import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ChangePasswordModal from './ChangePasswordModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlField from 'components/fields/ControlField';
import { Button, ButtonsContainer, LoadingButton, Input } from '@forma/forma-ui-kit';
import LangSwitch from 'components/LangSwitch';
import getValidationSchema from 'data/validationSchema';

import {
  useSetProfileDataMutation,
  // useGetGroupsListQuery,
  // useGetUserGroupsQuery,
  // useSetUserGroupsMutation
} from 'store/user/userApi';
import {
  selectUserData,
  // selectUserPermissions
} from 'store/user/userSlice';
import { setNotification } from 'store/notifications/notificationsSlice';

import styles from './Profile.module.css';

const fields = [ 'name', 'login', 'phone' ];

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  // const userPermissions = useSelector(selectUserPermissions);
  // const canChangeUsers = userPermissions && !!userPermissions.find(pName => pName === 'users');

  const [ isShowChangePass, setShowChangePass ] = useState(false);
  // const [ newUserGroups, setNewUserGroups ] = useState(null);

  // const { data: groups } = useGetGroupsListQuery(undefined, { skip: !canChangeUsers });
  // const { data: userGroups } = useGetUserGroupsQuery(user && user.id, { skip: !user || !user.id });

  const [ saveUserData, { isLoading: isSaveDataLoading } ] = useSetProfileDataMutation();
  // const [ saveUserGroups, { isLoading: isSaveGoupsLoading } ] = useSetUserGroupsMutation();

  const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm({
    mode: 'onChange', resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit = async (data) => {
    // if (newUserGroups) saveUserGroups({ id: user.id, groupids: Object.keys(newUserGroups).filter(key => !!newUserGroups[key]) });
    const res = await saveUserData(data);

    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  useEffect(() => {
    if (user) {
      // todo: change to universal format phone
      const x = (user.phone && user.phone.length >= 11) && user.phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
      reset({ ...user, phone: x ? `+${x[1]}(${x[2]})-${x[3]}-${x[4]}-${x[5]}` : '' });
      // reset(user);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <AdminLayout title={t('my_account')}>
      <PageTitle>{t('site_name') + ' – ' + t('my_account')}</PageTitle>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          {!user ? (
            [1,2,3].map(key => (
              <div className={classNames(styles.input, 'skeleton-loader')} key={key} />
            ))
          ) : (
            user && fields.map(key => {
              return (
                <ControlField
                  component={Input}
                  id={key}
                  name={key}
                  key={key}
                  readOnly={key === 'login'}
                  placeholder={t(`placeholders.${key}`)}
                  register={register}
                  control={control}
                  className={styles.input}
                  meta={{
                    label: t(`fields.${key}`),
                    labelClass: styles.label,
                    containerClass: styles.inputContainer,
                    mask: null,
                    error: errors[key] && errors[key].message,
                    errorMessage: errors[key] && t(`errors.${errors[key].message}`)
                  }}
                />
              )
            })
          )}
        </div>
        <h2 className={styles.title}>{t('language')}</h2>
        <LangSwitch />

        <ButtonsContainer className={styles.buttons}>
          <Button viewStyle="secondary" shadow disabled>{t('change_email')}</Button>
          <Button viewStyle="secondary" shadow onClick={() => setShowChangePass(true)}>{t('change_password')}</Button>
          <LoadingButton
            type="submit"
            // isLoading={isSaveGoupsLoading || isSaveDataLoading}
            isLoading={isSaveDataLoading}
            viewStyle="primary"
            shadow
            disabled={!isValid}
          >
            {t('save')}
          </LoadingButton>
        </ButtonsContainer>
      </form>
      <ChangePasswordModal open={isShowChangePass} onClose={() => setShowChangePass(false)} />
    </AdminLayout>
  );
};

export default Profile;
