import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import StatisticCalendar from '../StatisticCalendar';

import styles from './statistic-cols.module.css';
import { Select } from '@forma/forma-ui-kit';

const startYear = 2023;

const StatisticCols = ({ params, week, month, quarter, year, onChangeMonth, onChangeYear }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.root}>
      <div className={styles.col}>
        <div className={styles.title}>Неделя:</div>
        <div className={styles.content}>
          {week?.values.map(({ date, count }) => (
            <div className={styles.dayRow} key={date}>
              <span className={styles.dayLabel}>{format(new Date(date), 'EEEEEE dd.MM', { locale: ru })}</span>
              <span className={styles.dayValue}>{count}</span>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <span className={styles.footerTitle}>Всего:</span>
          <span className={styles.footerValue}>{week?.total}</span>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.content}>
          <StatisticCalendar month={params.month} year={params.year} items={month?.values} onChangeMonth={onChangeMonth} />
        </div>
        <div className={styles.footer}>
          <span className={styles.footerLabel}>Всего:</span>
          <span className={styles.footerValue}>{month?.total}</span>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.title}>Квартал:</div>
        <div className={styles.content}>
          {quarter?.values.map(({ date, count }) => (
            <div className={styles.dayRow} key={date}>
              <span className={styles.dayLabel}>{format(new Date(date), 'LLLL', { locale: ru })}</span>
              <span className={styles.dayValue}>{count}</span>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <span className={styles.footerLabel}>Всего:</span>
          <span className={styles.footerValue}>{quarter?.total}</span>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.title}>
          Год
          <Select
            options={Array.from({ length: currentYear-startYear+1 }, (_, index) => ({ label: index+startYear, value: index+startYear }))}
            onChange={e => onChangeYear(e.target.value)}
            value={params.year ?? currentYear}
            meta={{
              containerClass: styles.select
            }}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.years}>
            {year?.values.map(({ date, count }) => (
              <div className={styles.dayRow} key={date}>
                <span className={styles.dayLabel}>{format(new Date(date), 'LLLL', { locale: ru })}</span>
                <span className={styles.dayValue}>{count}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <span className={styles.footerLabel}>Всего:</span>
          <span className={styles.footerValue}>{year?.total}</span>
        </div>
      </div>
    </div>
  );
};

export default StatisticCols;
