import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import VariablesHeader from './VariablesHeader';
import VariableEdit from 'components/variables/VariableEdit';
import { Modal, Button, RemoveModal, Table, TableRow, TableCell } from '@forma/forma-ui-kit';

import styles from './variables-list.module.css';

const VariablesItem = ({ id, name, type, countFolders, variblesTypes, onClick, onRemove, templatesCount }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className={styles.id} title={t('vars.id')} onClick={() => onClick(id)}>
        {id}
      </TableCell>
      <TableCell className={styles.name} title={t('vars.name')} onClick={() => onClick(id)}>
        {name}
      </TableCell>
      <TableCell className={styles.type} title={t('vars.type')} onClick={() => onClick(id)}>
        {variblesTypes && variblesTypes[type]?.name}
      </TableCell>
      <TableCell className={styles.groups} title="Групп" onClick={() => onClick(id)}>
        {(countFolders || countFolders === 0) && (
          countFolders === 0 ? (
            <ReactSVG src="/icons/attention.svg" wrapper="span" />
          ) : (
            countFolders
          )
        )}
      </TableCell>
      <TableCell className={styles.templates} title="Шаблонов" onClick={() => onClick(id)}>
        {templatesCount}
      </TableCell>
      <TableCell className={styles.button}>
        <RemoveModal
          control={<Button viewStyle="text" className={styles.remove}><ReactSVG src="/icons/trash.svg" /></Button>}
          title={t('delete_variable')}
          onRemove={() => onRemove(id)}
          itemName={name}
        />
      </TableCell>
    </TableRow>
  );
};

const VariablesList = ({
  variables, variblesTypes, variablesTemplates, sections, folders, onSearch, onChangePosition, onSave, onAdd, onRemove, lastId
}) => {
  const { t } = useTranslation();
  const [ isShowModal, setShowModal ] = useState(null);
  const [ variableId, setVariableId ] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setVariableId(null);
  };

  const handleSave = (data) => {
    onSave(data);
    setShowModal(false);
    setVariableId(null);
  };

  const handleAdd = (data) => {
    onAdd(data);
    setShowModal(false);
    setVariableId(null);
  };

  const handleClickAdd = () => {
    setShowModal(true);
  };

  const handleClickVariable = (id) => {
    setVariableId(id);
    setShowModal(true);
  };

  return (
    <div className={styles.root}>
      <VariablesHeader onChangeSearch={onSearch} onClickAdd={handleClickAdd} />
      <Table className={styles.table} columns={[
        { children: t('vars.id') },
        { children: t('vars.name') },
        { children: t('vars.type') },
        { children: 'Групп' },
        { children: 'Шаблонов' },
        { children: '' },
      ]}>
        {variables?.map(item => (
          <VariablesItem
            {...item}
            countFolders={folders && Object.values(folders).filter(({ attrs }) => attrs.includes(String(item.id))).length}
            variblesTypes={variblesTypes}
            variablesSections={sections}
            onClick={handleClickVariable}
            onChangePosition={onChangePosition}
            onRemove={onRemove}
            templatesCount={variablesTemplates?.find(({ id }) => item.id === id)?.templates?.length}
            key={item.id}
          />
        ))}
      </Table>

      <Modal
        open={isShowModal}
        onClose={handleClose}
        className={styles.root}
        title={variableId ? t('edit_variable') : t('add_variable')}
        closeOnDocumentClick={false}
        size="extraLarge"
      >
        {variables &&
          <VariableEdit
            variblesTypes={variblesTypes}
            onCancel={handleClose}
            onSave={handleSave}
            onAdd={handleAdd}
            isAdd={!variableId || !variables.find(({ id }) => id === variableId)}
            data={variables.length && (
              variables.find(({ id }) => id === variableId) ??
              { priority: 0, id: lastId+1 }
            )}
            sections={sections}
            folders={folders}
            templates={variablesTemplates?.find(({ id }) => id === variableId)?.templates}
          />
        }
      </Modal>
    </div>
  );
};

export default VariablesList;
