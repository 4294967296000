import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import VariableEdit from 'components/variables/VariableEdit';
import { Modal, Button, RemoveModal } from '@forma/forma-ui-kit';

import styles from './variables-groups.module.css';

const VariableItem = ({ item, groupId, index, onCut, onPaste, onSave, onAdd, variblesTypes, sections, folders }) => {
  const { t } = useTranslation();
  const [ isShowModal, setShowModal ] = useState(null);
  const { id, name } = item;

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: `row`,
    item: () => ({ id, name, index, groupId }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) return null;
      const dropResult = monitor.getDropResult();
      if (dropResult?.dropEffect === 'move' && onCut) onCut(item);
    }
  }), [item]);

  const [{ isOver, canDrop } , dropRef] = useDrop(() => ({
    accept: `row`,
    item: { id, name, index, groupId },
    canDrop: (item) => (item.groupId !== groupId || (item.index !== index && item.index !== index+1)),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
    drop: (item, monitor) => {
      // pasting after dropping element index
      if (onPaste) onPaste(item, { index: index+1, groupId });
      return null;
    }
  }), [item]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = (data) => {
    onSave(data);
    setShowModal(false);
  };

  const handleAdd = (data) => {
    onAdd(data);
    setShowModal(false);
  };

  const handleClickVariable = () => {
    setShowModal(true);
  };

  return (
    <div
      className={styles.dropContainer}
      style={{ paddingBottom: (isOver && canDrop) ? '44px' : '0' }}
      ref={dropRef}
    >
      <div
        ref={dragRef}
        className={classNames(styles.listItem, isDragging && styles.dragging)}
        onClick={handleClickVariable}
      >
        <span>{id}</span> {name}

        <RemoveModal
          control={<Button viewStyle="text" className={styles.remove}><ReactSVG src="/icons/trash.svg" /></Button>}
          title={t('delete_variable_from_group')}
          onRemove={() => onCut({ id, index, name, groupId })}
          message={t('delete_variable_from_group_description')}
        />
      </div>

      <Modal
        open={isShowModal}
        onClose={handleClose}
        className={styles.root}
        title={t('edit_variable')}
        closeOnDocumentClick={false}
        size="extraLarge"
      >
        <VariableEdit
          variblesTypes={variblesTypes}
          onCancel={handleClose}
          onSave={handleSave}
          onAdd={handleAdd}
          data={item}
          folders={folders}
          sections={sections}
        />
      </Modal>
    </div>
  );
};

export default VariableItem;