import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Modal, Button, Input } from '@forma/forma-ui-kit';

import styles from './create-folder.module.css';

const CreateFolder = ({ parentid, disabled, onCreate }) => {
  const { t } = useTranslation();

  const [ name, setName ] = useState('');
  const [ isCreateItemOpen, setCreateItemOpen ] = useState(false);

  const handleCreateFolder = () => {
    if (!name || name.length < 3) return null;

    setCreateItemOpen(false);
    setTimeout(() => {
      if (onCreate) onCreate({ name, type: 'folder', parentid });
      setName('');
    }, 0);
  };

  return (
    <div>
      <Button
        id="create_folder"
        onClick={() => setCreateItemOpen(true)}
        viewStyle="secondary"
        icon={<ReactSVG src="/icons/folder-thin.svg" wrapper="span" />}
        shadow
        disabled={disabled}
      >
        {t('create_folder')}
      </Button>

      <Modal
        title={t('creating_folder')}
        open={isCreateItemOpen}
        onClose={() => setCreateItemOpen(false)}
        buttons={[
          {
            type: 'submit',
            children: t('create'),
            onClick: handleCreateFolder,
            disabled: name === '' || name.length < 3,
            closing: false
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary',
          }
        ]}
      >
        <div className={styles.modalContent}>
          <Input
            type="text"
            name="name"
            placeholder={t('enter_folder_name')}
            onChange={e => setName(e.target.value)}
            value={name}
            maxLength={255}
            meta={{
              showClearButton: true
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateFolder;
