import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input } from '@forma/forma-ui-kit';
import { useIncreaseSignatureMutation } from 'store/workspaces/workspacesApi';

const WorkspaceSignatureModal = ({ open, onClose, control, workspaceId }) => {
  const { t } = useTranslation();
  const [ signatureCount, setSignatureCount ] = useState('');

  const [ increaseSignature ] = useIncreaseSignatureMutation();

  const handleSubmit = () => {
    increaseSignature({ id: workspaceId, increase: Number(signatureCount) });
  };

  return (
    <Modal
      open={open}
      onClose={() => { onClose(false); setSignatureCount(''); }}
      control={control}
      title="Добавить ПЭП"
      size="extraSmall"
      buttons={[
        {
          children: t('submit'),
          disabled: !signatureCount,
          onClick: handleSubmit
        },
        {
          children: t('cancel'),
          viewStyle: 'tertiary',
        },
      ]}
    >
      <Input
        type="number"
        name="addSignature"
        viewStyle="secondary"
        placeholder="10"
        onChange={e => setSignatureCount(e.target.value)}
        value={signatureCount}
        meta={{
          label: 'Кол-во подписей',
        }}
      />
    </Modal>
  );
};

export default WorkspaceSignatureModal;
