import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Button, ButtonsContainer, RemoveModal, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import TemplateCategoryEditModal from './TemplateCategoryEditModal';

import styles from './template-categories-list.module.css';

const Item = ({ id, name, parentid, categories, onSave, onRemove }) => {
  const { t } = useTranslation();
  const [ isEditOpen, setEditOpen ] = useState(false);

  return (
    <TableRow>
      <TableCell className={styles.name} title={t('name')}>
        {name}
      </TableCell>
      <TableCell className={styles.parent} title={t('parent_category')}>
        {categories?.find(({ id }) => id === parentid)?.name}
      </TableCell>
      <TableCell className={styles.buttons}>
        <div>
          <Button
            viewStyle="text"
            onClick={() => setEditOpen(true)}
            icon={<ReactSVG src="/icons/edit.svg" />}
            shadow
          />
          <TemplateCategoryEditModal
            open={isEditOpen}
            onClose={() => setEditOpen(false)}
            title={t('edit_category')}
            data={{ id, name, parentid}}
            categories={categories?.filter(item => item.id !== id)}
            onSave={values => onSave({ id, ...values })}
          />
          <RemoveModal
            control={
              <Button viewStyle="text" className={styles.remove} iconClassName={styles.removeIcon} icon={<ReactSVG src="/icons/trash.svg" />} />
            }
            title={t('delete_category')}
            onRemove={() => onRemove(id)}
            itemName={name}
          />
        </div>
      </TableCell>
    </TableRow>
  )
};

const TemplateCategoriesList = ({ items, onSave, onAdd, onRemove }) => {
  const { t } = useTranslation();
  const [ isAddOpen, setAddOpen ] = useState(false);

  return (
    <div className={styles.root}>
      <ButtonsContainer className={styles.headerButtons}>
        <Button viewStyle="secondary" onClick={() => setAddOpen(true)} shadow>
          {t('add_category')}
        </Button>
        <TemplateCategoryEditModal
          open={isAddOpen}
          onClose={() => setAddOpen(false)}
          title={t('add_category')}
          categories={items}
          onSave={onAdd}
        />
      </ButtonsContainer>
      <Table className={styles.table} columns={[
        { children: t('name') },
        { children: t('parent_category') },
        { children: '' },
      ]}>
        {items && Object.values(items).map(item => (
          <Item
            {...item}
            categories={items}
            onSave={onSave}
            onRemove={onRemove}
            key={item.id}
          />
        ))}
      </Table>
    </div>
  );
};

export default TemplateCategoriesList;
