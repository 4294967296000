import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Modal, Button, RemoveModal, TableCell, TableRow } from '@forma/forma-ui-kit';
import PatternEdit from './PatternEdit';

import styles from './tariffs-list.module.css';

const PatternListItem = ({ canAddUsers, item, onSave, onRemove }) => {
  const { t } = useTranslation();
  const [ isEditOpen, setEditOpen ] = useState(false);

  const { id, name, duration, discount, price } = item;

  return (
    <TableRow className={styles.listItem}>
      <TableCell className={styles.id}>
        {id}
      </TableCell>
      <TableCell className={styles.name}>
        {name}
      </TableCell>
      <TableCell className={styles.duration}>
        {duration}
      </TableCell>
      <TableCell className={styles.price}>
        {price} / {discount}%
      </TableCell>
      <TableCell className={styles.buttons}>
        <Button viewStyle="text" className={styles.remove} title={t('edit')} onClick={() => setEditOpen(true)}>
          <ReactSVG src="/icons/edit.svg" />
        </Button>
        <Modal
          open={isEditOpen}
          onClose={() => setEditOpen(false)}
          title={t('edit_duration')}
          size="large"
        >
          <PatternEdit
            canAddUsers={canAddUsers}
            data={item}
            onCancel={() => setEditOpen(false)}
            onSave={data => { onSave(data); setEditOpen(false); }}
          />
        </Modal>

        <RemoveModal
          control={
            <Button viewStyle="text" className={styles.remove} title={t('delete')}>
              <ReactSVG src="/icons/trash.svg" />
            </Button>
          }
          title={t('delete_duration')}
          onRemove={() => onRemove(id)}
          itemName={name}
        />
      </TableCell>
    </TableRow>
  );
};

export default PatternListItem;
