import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Modal, Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { templateCategorySchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './template-category-edit.module.css';

const TemplateCategoryEditModal = ({ title, open, onClose, data, categories, onSave }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(templateCategorySchema, data)
    // resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit = (values) => {
    onSave(formValuesToData(templateCategorySchema, values));
    reset();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={styles.root}
      title={title}
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          {Object.keys(templateCategorySchema).map(key => {
              const attrs = { ...templateCategorySchema[key] };
              let options = attrs.options;
              if (key === 'parentid' && categories) options = categories.reduce((acc, { id, name }) => [ ...acc, { value: id, label: name } ], []);
              return (
                <FillingItem
                  data={{
                    ...attrs,
                    id: key,
                    required: attrs.required ?? false,
                    name: t(`vars.${key}`),
                    errorMessage: t('errors.regexp'),
                  }}
                  form={{
                    register: register,
                    control: control,
                    error: errors[key] && errors[key].message,
                  }}
                  options={options}
                  readOnly={data && key === 'id'}
                  key={key}
                />
              )
            }
          )}
        </div>
        <ButtonsContainer className={styles.buttons}>
          <Button
            viewStyle="tertiary"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            viewStyle="primary"
            disabled={!isValid}
          >
            {t('save')}
          </Button>
        </ButtonsContainer>
      </form>
    </Modal>
  );
};

export default TemplateCategoryEditModal;
