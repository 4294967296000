import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { Modal, Table } from '@forma/forma-ui-kit';
import SectionEdit from '../SectionEdit';
import SectionsHeader from './SectionsHeader';
import SectionItem from './SectionItem';

import styles from './sections-list.module.css';
import GroupEdit from '../GroupEdit';

const SectionsList = ({ sections, folders: availableFolders, variables, onSave, onAdd, onRemove, onAddGroup, onSaveGroup }) => {
  const { t } = useTranslation();
  const [ isShowModal, setShowModal ] = useState(null);
  const [ isShowModalGroup, setShowModalGroup ] = useState(false);
  const [ sectionId, setSectionId ] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setSectionId(null);
  };

  const handleSaveSection = (data) => {
    onSave(data);
    setShowModal(false);
    setSectionId(null);
  };

  const handleAddSection = (data) => {
    onAdd(data);
    setShowModal(false);
    setSectionId(null);
  };

  const handleClickAddSection = () => {
    setShowModal(true);
  };

  const handleClickEditSection = (id) => {
    setSectionId(id);
    setShowModal(true);
  };

  const handleClickAddGroup = () => {
    setShowModalGroup(true);
  };

  const handleAddGroup = (data) => {
    onAddGroup(data);
    setShowModalGroup(false);
  };

  const handleSaveGroup = (data) => {
    onSaveGroup(data);
    setShowModalGroup(false);
  };

  return (
    <div className={styles.root}>
      <SectionsHeader
        onClickAdd={handleClickAddSection}
        onClickAddGroup={handleClickAddGroup}
      />
      <DndProvider backend={HTML5Backend}>
        <Table className={styles.table} columns={[
          { children: t('id') },
          { children: t('name') },
          { children: t('code') },
          { children: '' },
        ]}>
          {sections && Object.values(sections).map((item) => (
            <SectionItem
              {...item}
              availableFolders={availableFolders}
              availableVariables={variables}
              onRemove={onRemove}
              onMoveFolders={folders => onSave({ ...item, folders })}
              onClickEdit={handleClickEditSection}
              onSaveGroup={handleSaveGroup}
              key={item.id}
            />
          ))}
        </Table>
      </DndProvider>

      <Modal
        open={isShowModal}
        onClose={handleClose}
        className={styles.root}
        title={sectionId ? t('edit_section') : t('add_section')}
        closeOnDocumentClick={false}
      >
        {sections &&
          <SectionEdit
            data={sections && (
              sections[sectionId] ??
              { priority: 0, id: Math.max(...Object.values(sections).map(({ id }) => Number(id)))+1 }
            )}
            folders={availableFolders}
            onCancel={handleClose}
            onSave={handleSaveSection}
            onAdd={handleAddSection}
            isAdd={!sectionId}
          />
        }
      </Modal>

      <Modal
        open={isShowModalGroup}
        onClose={() => setShowModalGroup(false)}
        className={styles.root}
        title={t('add_group')}
      >
        {(availableFolders && variables) &&
          <GroupEdit
            data={{ priority: 0, id: Math.max(...Object.values(availableFolders).map(({ id }) => Number(id)))+1 }}
            variables={variables}
            onCancel={() => setShowModalGroup(false)}
            onSave={handleSaveGroup}
            onAdd={handleAddGroup}
            isAdd={true}
          />
        }
      </Modal>
    </div>
  )
};

export default SectionsList;