import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Input, Select } from '@forma/forma-ui-kit';
import FileDropzone from 'components/fields/FileDropzone';
import UserIcon from '../UserIcon';
import PrioritySelect from '../PrioritySelect';

import { selectUserData } from 'store/user/userSlice';
import { useCreateTaskMutation } from 'store/tasks/tasksApi';

import styles from './task-create-modal.module.css';

const UserOptionLabel = ({ name, login, color, isSelf }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.option}>
      <UserIcon name={name} color={color} />
      <div>
        <div>{name || login}</div>
        {isSelf && <div className={styles.forSelf}>{t('assign_for_yourself')}</div>}
      </div>
    </div>
  );
};

const TaskCreateModal = ({ open, onClose, admins }) => {
  const { t } = useTranslation();
  const [ data, setData ] = useState({ type: 'TCREQUEST' });
  const user = useSelector(selectUserData);
  const [ createTask, { isLoading } ] = useCreateTaskMutation();

  const handleClickSubmit = () => {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    createTask(formData);

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('creating_task')}
      size="large"
      buttons={[
        {
          viewStyle: 'primary',
          className: styles.submitButton,
          children: t('create'),
          isLoading: isLoading,
          disabled: !(data.file && data.type && data.priority && (data.type === 'SHAREDTEMPLATE' || data.creatorlogin) && data.managerlogin && data.description),
          onClick: handleClickSubmit
        }
      ]}
    >
      <ol className={styles.list}>
        <li className={styles.row}>
          <div className={styles.head}>
            <div className={styles.search}>
              <Select
                className={styles.searchSelect}
                placeholder={t('enter_account_email_of_workspace')}
                onChange={e => setData(prev => ({ ...prev, creatorlogin: e.target.value, type: 'TCREQUEST' }))}
                value={data?.creatorlogin}
                meta={{
                  loadingMethod: 'get',
                  loadingUrl: '/workspaces/',
                  loadingParam: 'login',
                  loadingMapper: data => data.map(({ id, owner }) => ({ label: owner.login, value: owner.login }))
                }}
              />
            </div>
            <span>{t('or')}</span>
            <Button
              className={styles.headButton}
              viewStyle={data.type === 'SHAREDTEMPLATE' ? 'primary' : 'tertiary'}
              onClick={() => setData(prev => ({ ...prev, type: 'SHAREDTEMPLATE' }))}
            >
              {t('forma_template')}
            </Button>
          </div>
        </li>
        <li className={styles.row}>
          <Select
            className={styles.select}
            options={admins?.map(({ name, login, color }) => (
              {
                label: <UserOptionLabel name={name} login={login} color={color} isSelf={user?.login === login} />,
                value: login,
                displayLabel: <>{t('executor')}: {name || login}</>
              }
            )) ?? []}
            placeholder={t('select_executor')}
            onChange={e => setData(prev => ({ ...prev, managerlogin: e.target.value }))}
            meta={{
              customLabelKey: 'displayLabel'
            }}
          />
        </li>
        <li className={styles.row}>
          <PrioritySelect
            className={styles.select}
            onChange={e => setData(prev => ({ ...prev, priority: e.target.value }))}
          />
        </li>
        <li className={styles.row}>
          <div>{t('upload_needed_template_for_setting')}</div>
          <FileDropzone
            className={styles.dropzone}
            onChange={files => setData(prev => ({ ...prev, file: files[0] }))}
            maxFiles={1}
          />
        </li>
        <li className={styles.row}>
          <div>{t('task_name')}</div>
          <Input
            onChange={e => setData(prev => ({ ...prev, description: e.target.value }))}
            className={styles.comment}
            placeholder={t('task_name')}
            maxLength={250}
          />
        </li>
      </ol>
    </Modal>
  );
};

export default TaskCreateModal;
