import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Modal, Button, RemoveModal, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import ResourceEdit from './ResourceEdit';
import ResourcesHeader from './ResourcesHeader';

import styles from './resources-list.module.css';

const Item = ({ id, lang, value, onSave, onRemove, onClick }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className={styles.id} title={t('id')} onClick={() => onClick({ id, lang, value })}>
        {id}
      </TableCell>
      <TableCell className={styles.lang} title={t('lang')} onClick={() => onClick({ id, lang, value })}>
        {lang}
      </TableCell>
      <TableCell className={styles.value} title={t('value')} onClick={() => onClick({ id, lang, value })}>
        {value}
        {/* <Textarea
          className={styles.input}
          onChange={e => setValue(e.target.value)}
          value={value}
          maxLength="9999"
        /> */}
        {/* <Button
          viewStyle={butStyles.text}
          className={styles.save}
          onClick={() => onSave({ id, lang, value })}
        >
          <ReactSVG src="/icons/floppy-disk.svg" />
        </Button> */}
      </TableCell>
      <TableCell className={styles.button}>
        <RemoveModal
          control={<Button viewStyle="text" className={styles.remove}><ReactSVG src="/icons/trash.svg" /></Button>}
          title={t('delete_resource')}
          onRemove={() => onRemove({ id, lang })}
          itemName={id}
        />
      </TableCell>
    </TableRow>
  )
}

const ResourcesList = ({ items, onSave, onAdd, onRemove, onSearch }) => {
  const { t } = useTranslation();
  const [ isShowModal, setShowModal ] = useState(null);
  const [ editResource, setEditResource ] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setEditResource(null);
  };

  const handleSave = (data) => {
    onSave(data);
    setShowModal(false);
    setEditResource(null);
  };

  const handleAdd = (data) => {
    onAdd(data);
    setShowModal(false);
    setEditResource(null);
  };

  const handleClickAdd = () => {
    setShowModal(true);
  };

  const handleClick = (item) => {
    setShowModal(true);
    setEditResource(item);
  };

  return (
    <div className={styles.root}>
      <ResourcesHeader
        onClickAdd={handleClickAdd}
        onChangeSearch={onSearch}
      />
      <Table className={styles.table} columns={[
        { children: t('id') },
        { children: t('language') },
        { children: t('value') },
        { children: '' },
      ]}>
        {items && Object.values(items).map(({ id, lang, value }) => (
          <Item
            id={id}
            lang={lang}
            value={value}
            onSave={onSave}
            onRemove={onRemove}
            onClick={handleClick}
            key={`${id}_${lang}`}
          />
        ))}
      </Table>

      <p>Total items: {items?.length}</p>

      <Modal
        open={isShowModal}
        onClose={handleClose}
        className={styles.root}
        title={editResource ? t('edit_resource') : t('add_resource')}
      >
        {items &&
          <ResourceEdit
            template={items.find(({ id }) => id === 'email.template')}
            onCancel={handleClose}
            onSave={handleSave}
            onAdd={handleAdd}
            data={editResource}
          />
        }
      </Modal>
    </div>
  )
};

export default ResourcesList;