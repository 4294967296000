import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ButtonsContainer, Table } from '@forma/forma-ui-kit';
import PromocodesListItem from './PromocodesListItem';
import PromocodeEdit from './PromocodeEdit';

import styles from './promocodes-list.module.css';

const PromocodesList = ({ items, tariffs, onAdd, onSave, onRemove }) => {
  const { t } = useTranslation();
  const [ isAddOpen, setAddOpen ] = useState(false);

  const promoTypes = [
    { value: 'discountPercent', label: t('discountPercent') },
    { value: 'discountBasePercent', label: t('discountBasePercent') },
    { value: 'discountAmount', label: t('discountAmount') },
    { value: 'tariff', label: t('tariff') },
    { value: 'trialProlong', label: t('trialProlong') }
  ];

  return (
    <div className={styles.root}>
      <Table className={styles.table} columns={[
        { children: t('id') },
        { children: t('name') },
        { children: t('type') },
        { children: t('value') },
        { children: t('date') },
        { children: '' }
      ]}>
        {items?.map(item => (
          <PromocodesListItem
            item={item}
            tariffs={tariffs}
            promoTypes={promoTypes}
            onSave={onSave}
            onRemove={onRemove}
            key={item.id}
          />
        ))}
      </Table>
      <ButtonsContainer>
        <Button className={styles.addButton} viewStyle="primary" onClick={() => setAddOpen(true)}>
          {t('add_promocode')}
        </Button>
        <Modal
          open={isAddOpen}
          onClose={() => setAddOpen(false)}
          title={t('add_promocode')}
          size="large"
        >
          <PromocodeEdit
            tariffs={tariffs}
            promoTypes={promoTypes}
            onCancel={() => setAddOpen(false)}
            onAdd={data => { setAddOpen(false); onAdd(data); }}
            isAdd
          />
        </Modal>
      </ButtonsContainer>
    </div>
  );
};

export default PromocodesList;
