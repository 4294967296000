import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlField from 'components/fields/ControlField';
import { Modal, ButtonsContainer, LoadingButton, Input } from '@forma/forma-ui-kit';
import getValidationSchema from 'data/validationSchema';

import {
  useSetProfilePasswordMutation,
} from 'store/user/userApi';
import {
  setNotification
} from 'store/notifications/notificationsSlice';

import styles from './Profile.module.css';

const ChangePasswordModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ isShowPass, setShowPass ] = useState(null);

  const [ setPassword, { isLoading } ] = useSetProfilePasswordMutation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'oldPassword', 'password', 'confirm_password' ]))
  });

  const onSubmit = async (data) => {
    const _data = {
      oldPassword: data.oldPassword,
      newPassword: data.password
    };

    const res = await setPassword(_data);
    if (res && !res.error) {
      onClose();
      dispatch(setNotification(t('password_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleClickPassword = (id) => {
    document.getElementById(id).focus();
    setShowPass(!isShowPass);
  };

  return (
    <Modal
      open={open}
      title={t('change_password')}
    >
      <form className={styles.passwordForm} onSubmit={handleSubmit(onSubmit)}>
        <ControlField
          component={Input}
          id="oldPassword"
          type={isShowPass ? 'text' : 'password'}
          name="oldPassword"
          placeholder={t('not_input')}
          control={control}
          autoComplete="off"
          className={styles.input}
          {...register('oldPassword')}
          meta={{
            label: t('account.input_old_password'),
            labelClass: styles.label,  
            containerClass: styles.inputContainer,
            buttons: [ <img src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'} alt="" onClick={() => handleClickPassword('oldPassword')} /> ],
            error: (errors.oldPassword && errors.oldPassword.message),
          }}
        />
        <ControlField
          component={Input}
          id="password"
          type={isShowPass ? 'text' : 'password'}
          name="password"
          placeholder={t('not_input')}
          control={control}
          autoComplete="off"
          className={styles.input}
          {...register('password')}
          meta={{
            label: t('account.input_new_password'),
            labelClass: styles.label,  
            containerClass: styles.inputContainer,
            buttons: [ <img src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'} alt="" onClick={() => handleClickPassword('password')} /> ],
            error: (errors.password && errors.password.message),
          }}
        />
        <ControlField
          component={Input}
          id="confirm_password"
          type={isShowPass ? 'text' : 'password'}
          name="confirm_password"
          placeholder={t('not_input')}
          control={control}
          autoComplete="off"
          className={styles.input}
          {...register('confirm_password')}
          meta={{
            label: t('account.repeat_new_password'),
            labelClass: styles.label,  
            containerClass: styles.inputContainer,
            buttons: [ <img src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'} alt="" onClick={() => handleClickPassword('confirm_password')} /> ],
            error: (errors.confirm_password && errors.confirm_password.message),
          }}
        />

        <ButtonsContainer>
          <LoadingButton
            type="submit"
            viewStyle="primary"
            disabled={!isValid}
            fullWidth
            isLoading={isLoading}
          >
            {t('save_password')}
          </LoadingButton>
        </ButtonsContainer>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
