import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { strictattrsSectionsSchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './section-edit.module.css';

const SectionEdit = ({ data, folders, onCancel, onSave, onAdd, isAdd }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(strictattrsSectionsSchema, data)
    // resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit = (values) => {
    isAdd ? onAdd(formValuesToData(strictattrsSectionsSchema, values)) : onSave(formValuesToData(strictattrsSectionsSchema, values));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        {Object.keys(strictattrsSectionsSchema).map(key => {
            const attrs = { ...strictattrsSectionsSchema[key] };
            let options = attrs.options;
            if (key === 'folders') {
              options = Object.values(folders).reduce((prev, { id, name }) => [ ...prev, { value: String(id), label: name } ], []);
            }

            return (
              <FillingItem
                data={{
                  ...attrs,
                  id: key,
                  required: attrs.required ?? false,
                  name: t(`vars.${key}`),
                  errorMessage: t('errors.regexp'),
                }}
                form={{
                  register: register,
                  control: control,
                  error: errors[key] && errors[key].message,
                }}
                options={options}
                readOnly={data && key === 'id'}
                key={key}
              />
            )
          }
        )}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          viewStyle="tertiary"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          viewStyle="primary"
          disabled={!isValid}
        >
          {t('save')}
        </Button>
      </ButtonsContainer>
    </form>
  )
};

export default SectionEdit;
