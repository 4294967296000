import { baseApi } from 'store/store';

export const workspacesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getWorkspaces: builder.query({
      query: data => ({
        url: '/workspaces/',
        method: 'GET',
        params: data
      }),
      providesTags: ['Workspaces']
    }),
    removeWorkspace: builder.mutation({
      query: id => ({
        url: `/workspaces/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TariffsHistory', 'Workspaces', 'WorkspaceAnalyticsSearch', 'WorkspaceAnalytics']
    }),
    getToken: builder.mutation({
      query: data => ({
        url: '/workspaces/token',
        method: 'POST',
        body: data
      })
    }),
    increaseSignature: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/workspaces/${id}/signaturelimit/increase`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['TariffsHistory', 'Workspaces', 'WorkspaceAnalyticsSearch', 'WorkspaceAnalytics']
    }),

    getScoringList: builder.query({
      query: data => ({
        url: '/userpoints/resources/',
        method: 'GET'
      }),
      providesTags: ['ScoringList'],
    }),
    changeScoring: builder.mutation({
      query: data => ({
        url: '/userpoints/resources/batch',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ScoringList'],
    }),
    changeScoringItem: builder.mutation({
      query: data => ({
        url: `/userpoints/resources/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ScoringList'],
    }),

    getExternalInfillEntities: builder.query({
      query: wid => ({
        url: `/externalinfillentitiesadmin/${wid}`,
        method: 'GET',
      }),
      providesTags: (result, error, wid) => [{ type: 'ExternalInfillEntities', id: wid }]
    }),
    setExternalInfillEntityApproved: builder.mutation({
      query: ({ wid, id, ...data }) => ({
        url: `/externalinfillentitiesadmin/${wid}/${id}/approved`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, { wid }) => [{ type: 'ExternalInfillEntities', id: wid }]
    }),
    addExternalInfillEntity: builder.mutation({
      query: ({ wid, ...data }) => ({
        url: `/externalinfillentitiesadmin/${wid}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, { wid }) => [{ type: 'ExternalInfillEntities', id: wid }]
    }),
    removeExternalInfillEntity: builder.mutation({
      query: ({ wid, id }) => ({
        url: `/externalinfillentitiesadmin/${wid}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { wid }) => [{ type: 'ExternalInfillEntities', id: wid }]
    }),
  })
});

export const {
  useGetWorkspacesQuery,
  useRemoveWorkspaceMutation,
  useGetTokenMutation,
  useIncreaseSignatureMutation,

  useGetScoringListQuery,
  useChangeScoringMutation,
  useChangeScoringItemMutation,
  useGetExternalInfillEntitiesQuery,
  useSetExternalInfillEntityApprovedMutation,
  useAddExternalInfillEntityMutation,
  useRemoveExternalInfillEntityMutation
} = workspacesApi;
