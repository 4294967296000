import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { format, subDays, subMonths, setDate, getDaysInMonth } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Modal, Button, DateInput } from '@forma/forma-ui-kit';

import styles from './workspace-search-filters.module.css';

const now = new Date();

const DatesModal = ({ from, to, onChange }) => {
  const { t } = useTranslation();
  const [ dateFrom, setFrom ] = useState(from);
  const [ dateTo, setTo ] = useState(to);

  return (
    <Modal
      title={t('select_period')}
      control={
        <Button
          className={classNames(styles.filtersButton, (from && to) && styles.withDate)}
          iconClassName={styles.filtersButtonIcon}
          viewStyle="secondary"
          icon={(from && to) && <ReactSVG src="/icons/delete-circle.svg" wrapper="span" onClick={(e) => { onChange('', ''); e.stopPropagation(); } } />}
          fullWidth
        >
          {(from && to) ?
            <><span className="nowrap">{format(new Date(from + 'T00:00:01'), 'dd.MM.yyyy')} -</span> <span className="nowrap">{format(new Date(to + 'T00:00:01'), 'dd.MM.yyyy')}</span></>
            : t('select_period')
          }
        </Button>
      }
      buttons={[
        {
          className: styles.modalButton,
          viewStyle: 'tertiary',
          children: t('cancel')
        },
        {
          onClick: () => onChange(dateFrom, dateTo),
          className: styles.modalButton,
          viewStyle: 'primary',
          children: t('submit')
        }
      ]}
    >
      <div className={styles.modal}>
        <input type="hidden" autoFocus />
        <DateInput
          name="from"
          viewStyle="secondary"
          className={styles.dateInput}
          value={dateFrom && new Date(dateFrom)}
          onChange={e => setFrom(e.target.value)}
          meta={{
            label: t('date_start'),
            labelClass: styles.dateLabel,
            icon: <ReactSVG src="/icons/calendar.svg" wrapper="span" />,
            portalId: 'popup-root',
            showIconAlways: true
          }}
        />
        <DateInput
          name="to"
          viewStyle="secondary"
          className={styles.dateInput}
          value={dateTo && new Date(dateTo)}
          onChange={e => setTo(e.target.value)}
          meta={{
            label: t('date_end'),
            labelClass: styles.dateLabel,
            icon: <ReactSVG src="/icons/calendar.svg" wrapper="span" />,
            portalId: 'popup-root',
            showIconAlways: true
          }}
        />
      </div>
    </Modal>
  );
};

const FiltersItem = ({ name, from, to, items, onSelect }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <div className={styles.filtersItem}>
      <Button
        className={styles.filtersLink}
        iconClassName={classNames(styles.filtersLinkIcon, isOpen && styles.open)}
        viewStyle="text"
        onClick={() => items ? setIsOpen(prev => !prev) : onSelect(from, to)}
        icon={items && <ReactSVG src="/icons/dropdown_thin.svg" wrapper="span" />}
        fullWidth
        key={name}
      >
        {name}
      </Button>
      {(isOpen && items) && (
        <div className={styles.filtersSubitems}>
          {items?.map(item => (
            <FiltersItem {...item} onSelect={onSelect} key={item.name} />
          ))}
        </div>
      )}
    </div>
  );
};

const WorkspaceSearchFilters = ({ from, to, onFilter }) => {
  const { t } = useTranslation();
  const periods = [
    {
      name: 'Сегодня',
      from: format(now, 'yyyy-MM-dd'),
      to: format(now, 'yyyy-MM-dd')
    },
    {
      name: 'Вчера',
      from: format(subDays(now, 1), 'yyyy-MM-dd'),
      to: format(subDays(now, 1), 'yyyy-MM-dd')
    },
    {
      name: t('month_names.' + (subMonths(now, 1).getMonth()+1)),
      items: Array.from({ length: getDaysInMonth(subMonths(now, 1)) }, (_, dayIndex) => ({
        name: format(setDate(subMonths(now, 1), dayIndex+1), 'd MMMM, iiiiii', { locale: ru }),
        from: format(setDate(subMonths(now, 1), dayIndex+1), 'yyyy-MM-dd'),
        to: format(setDate(subMonths(now, 1), dayIndex+1), 'yyyy-MM-dd')
      }))
    },
    {
      name: t('month_names.' + (subMonths(now, 2).getMonth()+1)),
      items: Array.from({ length: getDaysInMonth(subMonths(now, 2)) }, (_, dayIndex) => ({
        name: format(setDate(subMonths(now, 2), dayIndex+1), 'd MMMM, iiiiii', { locale: ru }),
        from: format(setDate(subMonths(now, 2), dayIndex+1), 'yyyy-MM-dd'),
        to: format(setDate(subMonths(now, 2), dayIndex+1), 'yyyy-MM-dd')
      }))
    },
    {
      name: t('month_names.' + (subMonths(now, 3).getMonth()+1)),
      items: Array.from({ length: getDaysInMonth(subMonths(now, 3)) }, (_, dayIndex) => ({
        name: format(setDate(subMonths(now, 3), dayIndex+1), 'd MMMM, iiiiii', { locale: ru }),
        from: format(setDate(subMonths(now, 3), dayIndex+1), 'yyyy-MM-dd'),
        to: format(setDate(subMonths(now, 3), dayIndex+1), 'yyyy-MM-dd')
      }))
    }
  ];

  return (
    <div className={styles.filters}>
      <DatesModal
        from={from}
        to={to}
        onChange={(createdAt, createdTo) => onFilter({ createdAt, createdTo }) }
      />
      {periods.map(item => (
        <FiltersItem
          {...item}
          onSelect={(createdAt, createdTo) => onFilter({ createdAt, createdTo })}
          key={item.name}
        />
      ))}
    </div>
  );
};

export default WorkspaceSearchFilters;
