import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Table, TableCell, TableRow } from '@forma/forma-ui-kit';

import styles from './demo-requests-list.module.css';

const DemoRequestsItem = ({ timeslotId, date, managerId, clientFullName, companyName, clientEmail }) => {
  const navigate = useNavigate();
  const link = `/demo-requests/?managerId=${managerId}&timeslotId=${timeslotId}`;

  return (
    <TableRow>
      <TableCell className={styles.cellDate} onClick={() => navigate(link)}>
        {moment(date).utcOffset('+03:00').format('DD.MM.yyyy HH:mm')}
      </TableCell>
      <TableCell className={styles.cellName} onClick={() => navigate(link)}>
        {clientFullName}
      </TableCell>
      <TableCell className={styles.cellCompany} onClick={() => navigate(link)}>
        {companyName}
      </TableCell>
      <TableCell className={styles.cellEmail} onClick={() => navigate(link)}>
        {clientEmail}
      </TableCell>
    </TableRow>
  );
};

const DemoRequestsAdd = ({ managerId }) => {
  const { t } = useTranslation();

  return (
    <TableRow className={styles.buttons}>
      <TableCell colSpan={4}>
        <Button
          as={Link}
          viewStyle="primary"
          size="small"
          className={styles.button}
          to={`/demo-requests/?managerId=${managerId}&create=true`}
        >
          {t('add_meeting')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const DemoRequestsList = ({ items, managerId }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Table columns={[
        { children: t('date_utc_3') },
        { children: t('fields.firstName') },
        { children: t('company') },
        { children: t('email_short') },
      ]}>
        {items?.toReversed().map(item => (
          <DemoRequestsItem {...item} key={item.id} />
        ))}

        <DemoRequestsAdd managerId={managerId}/>
      </Table>
    </div>
  );
};

export default DemoRequestsList;
