import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Button, Tooltip, TableCell, TableRow } from '@forma/forma-ui-kit';
import WorkspaceActions from './WorkspaceActions';

import styles from './workspaces-list.module.css';

const WorkspacesListItem = ({
  data, tariffs, actions
}) => {
  const { t } = useTranslation();

  const {
    user, currentUsersCount, templatesCount, rendersCount, tariff, dtFrom, dtTo, workspace,
    isPaid, accountingdetails, tcrequestsNotProcessedCount, featured
  } = data;

  const { onAddFavourite, onRemoveFavourite } = actions;

  return (
    <TableRow>
      <TableCell className={styles.account} title={t('user_account')}>
        <div>{user.login}</div>
        {accountingdetails?.inn && (
          <div className={styles.accountInn}>{t('inn')}: {accountingdetails.inn}</div>
        )}
        <div><Link to={`/workspaces/${workspace.id}`}>{t('go_to_details_analytics')}</Link></div>
      </TableCell>
      <TableCell className={styles.count} title={t('count_users')}>
        <Tooltip control={<div>{currentUsersCount}</div>}>{t('count_users')}</Tooltip>
      </TableCell>
      <TableCell className={styles.count} title={t('templates_created')}>
        <Tooltip control={<div>{templatesCount}</div>}>{t('templates_created')}</Tooltip>
      </TableCell>
      <TableCell className={styles.count} title={t('documents_created')}>
        <Tooltip control={<div>{rendersCount}</div>}>{t('documents_created')}</Tooltip>
      </TableCell>
      <TableCell className={styles.count} title={t('documents_signature')}>
        <Tooltip control={<div>{workspace.signature_used}/{workspace.signature_available}</div>}>{t('documents_signature')}</Tooltip>
      </TableCell>
      <TableCell className={styles.count} title={t('safe_used')}>
        <Tooltip control={<div>{workspace.attachments_used}/{workspace.attachments_available}</div>}>{t('safe_used')}</Tooltip>
      </TableCell>
      <TableCell className={styles.tariff} title={t('tariff')}>
        <div className={styles.tariffName}>{tariff.name}</div>
        <div className={styles.tariffInfo}>
          {isPaid ? (
            (dtFrom && dtTo) &&
              t('paid_from_to', { from: format(new Date(dtFrom), 'dd.MM.yyyy'), to: format(new Date(dtTo), 'dd.MM.yyyy') })
          ) : (
            t('not_paid')
          )}
        </div>
        <div className={styles.tariffInfo}>
          {new Date(dtTo) - new Date() > 0 ? (
            t('active_for_days', { count: Math.floor((new Date(dtTo) - new Date()) / (1000 * 60 * 60 * 24)) })
          ) : (
            t('not_active')
          )}
        </div>
      </TableCell>
      <TableCell className={styles.date} title={t('last_login')}>
        {user.lastValidDate && format(new Date(user.lastValidDate), 'dd.MM.yyyy')} <br />
        {user.lastValidDate && format(new Date(user.lastValidDate), 'HH:mm')}
      </TableCell>
      <TableCell className={styles.date} title={t('registration')}>
        {workspace.createdAt && format(new Date(workspace.createdAt), 'dd.MM.yyyy')} <br />
        {workspace.createdAt && format(new Date(workspace.createdAt), 'HH:mm')}
      </TableCell>
      <TableCell className={styles.buttons}>
        <div className={styles.buttonsContainer}>
          {!!tcrequestsNotProcessedCount && (
            <Tooltip
              control={<img src="/icons/attention.svg" alt="" />}
            >
              {t('templates_to_fill_count', { count: tcrequestsNotProcessedCount })}
            </Tooltip>
          )}
          <Button
            className={styles.button}
            viewStyle="text"
            icon={<ReactSVG src={featured ? '/icons/favorite.svg' : '/icons/favorite-empty.svg'} wrapper="span" />}
            onClick={() => featured ? onRemoveFavourite(workspace.id) : onAddFavourite(workspace.id)}
            title={t('favourites')}
          />
          
          <WorkspaceActions
            data={data}
            tariffs={tariffs}
            actions={actions}
          />
        </div>
      </TableCell>
    </TableRow>
  )
};

export default WorkspacesListItem;
