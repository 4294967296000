import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlField from 'components/fields/ControlField';
import { LoadingButton, Input } from '@forma/forma-ui-kit';
import { MainLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import { useLoginUserMutation } from 'store/auth/authApi';
import getValidationSchema from 'data/validationSchema';

import styles from './account.module.css';

const Login = () => {
  const { t } = useTranslation();
  const [ isShowPass, setShowPass ] = useState(false);
  const [ loginUser, { isLoading, isSuccess, error, isError } ] = useLoginUserMutation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
      mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'login', 'pass' ]))
    });
  const onSubmit = (data) => loginUser(data);

  useEffect(() => {
    if (isSuccess) console.log('User login successfully');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleClickPassword = (id) => {
    document.getElementById(id).focus();
    setShowPass(!isShowPass);
  };

  return (
    <MainLayout location="login">
      <PageTitle>{t('site_name') + ' – ' + t('login')}</PageTitle>
      <h1 className={styles.title}>{t('do_login')}</h1>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          <ControlField
            component={Input}
            name="login"
            placeholder={t('email')}
            control={control}
            {...register('login')}
            meta={{
              error: errors.login && errors.login.message,
              showClearButton: true
            }}
          />
          <ControlField
            component={Input}
            id="password"
            type={isShowPass ? 'text' : 'password'}
            name="password"
            placeholder={t('password')}
            control={control}
            {...register('password')}
            meta={{
              buttons: [ <img src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'} alt="" onClick={() => handleClickPassword('password')} key="show_pass" /> ],
              error: errors.password && errors.password.message,
            }}
          />
        </div>
        <div className={styles.errors}>
          {errors &&
            Object.keys(errors).map(key => (errors[key].message ? <div className={styles.error} key={key}>{t('errors.' + errors[key].message)}</div> : null))
          }
          {(isError && error && error.data && error.data.error) &&
            error.data.error.map((value, index) => <div className={styles.error} key={index}>{value}</div>)
          }
        </div>
        <div className={styles.buttonsContainer}>
          <LoadingButton type="submit" disabled={!isValid} isLoading={isLoading} fullWidth shadow>{t('sign_in')}</LoadingButton>
        </div>
      </form>
    </MainLayout>
  );
};

export default Login;
