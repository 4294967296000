import { useTranslation } from 'react-i18next';
import { Button, Select } from '@forma/forma-ui-kit';
import SearchInput from 'components/SearchInput';

import { adminRoles } from 'data/mock';

import styles from './admins-list-header.module.css';

const AdminsListHeader = ({ onChangeSearch, onChangeFilter, onClickAdd }) => {
  const { t } = useTranslation();
  const options = adminRoles?.map(key => ({ label: key, value: key }));

  const handleChangeCategories = (e) => {
    onChangeFilter({ roles: e.target.value });
  };

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('enter_name_or_email')}
      />
      <div className={styles.select}>
        <Select
          name="usergroups"
          multiple
          onChange={handleChangeCategories}
          options={options}
          placeholder={t('filter_by_group')}
          viewStyle="secondary"
        />
      </div>
      <Button className={styles.button} viewStyle="secondary" onClick={onClickAdd} shadow>{t('add')}</Button>
    </div>
  );
};

export default AdminsListHeader;
