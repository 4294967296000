import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Modal, Button, RemoveModal, TableCell, TableRow } from '@forma/forma-ui-kit';
import TariffEdit from './PromocodeEdit';

import styles from './promocodes-list.module.css';

const PromocodesListItem = ({ item, tariffs, promoTypes, onSave, onRemove }) => {
  const { t } = useTranslation();
  const [ isEditOpen, setEditOpen ] = useState(false);

  const { id, type, description, dtFrom, dtTo, discountPercent, discountAmount, trialProlongDays, patternId } = item;

  return (
    <TableRow>
      <TableCell className={styles.id} onClick={() => setEditOpen(true)}>
        {id}
      </TableCell>
      <TableCell className={styles.name} onClick={() => setEditOpen(true)}>
        {description}
      </TableCell>
      <TableCell className={styles.type} onClick={() => setEditOpen(true)}>
        {promoTypes[type]}
      </TableCell>
      <TableCell className={styles.value} onClick={() => setEditOpen(true)}>
        {patternId}
        {discountPercent}
        {discountAmount}
        {trialProlongDays}
      </TableCell>
      <TableCell className={styles.date} onClick={() => setEditOpen(true)}>
        {format(new Date(dtFrom), 'dd.MM.yyyy')} - {format(new Date(dtTo), 'dd.MM.yyyy')}
      </TableCell>
      <TableCell className={styles.buttons}>
        <div className={styles.buttonsContainer}>
          <Button viewStyle="text" className={styles.remove} title={t('edit')} onClick={() => setEditOpen(true)}>
            <ReactSVG src="/icons/edit.svg" />
          </Button>
          <Modal
            open={isEditOpen}
            onClose={() => setEditOpen(false)}
            title={t('edit_promocode')}
            size="large"
          >
            <TariffEdit
              data={item}
              tariffs={tariffs}
              promoTypes={promoTypes}
              onCancel={() => setEditOpen(false)}
              onSave={data => { onSave({ ...data }); setEditOpen(false); }}
            />
          </Modal>

          <RemoveModal
            control={
              <Button viewStyle="text" className={styles.remove} title={t('delete')}>
                <ReactSVG src="/icons/trash.svg" />
              </Button>
            }
            title={t('delete_tariff')}
            onRemove={() => onRemove(id)}
            itemName={description}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default PromocodesListItem;
