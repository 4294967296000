import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';
import { formatPrice } from 'helpers/formatValues';
import { currencies } from 'data/mock';

import { setNotification } from 'store/notifications/notificationsSlice';
import { useSetTariffPayMutation } from 'store/tariffs/tariffsApi';

import styles from './WorkspaceModalStyles.module.css';

const WorkspacePayedModal = ({ open, onClose, control, instanceId, user, tariffName, patternName, price }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ setTariffPay ] = useSetTariffPayMutation();

  const handleSubmit = () => {
    setTariffPay({ id: instanceId, amount: price}).then(() => {
      dispatch(setNotification(t('tariff_mark_paid'), 'SUCCESS'));
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      control={control}
      title={t('mark_paid')}
      size="extraSmall"
      buttons={[
        {
          children: t('submit'),
          onClick: handleSubmit
        },
        {
          children: t('cancel'),
          viewStyle: 'tertiary'
        },
      ]}
    >
      <div className={styles.modalContent}>
        <div className={styles.infoTitle}>{t('user_data')}</div>
        <div className={styles.infoRow}>{t('user')}: {user?.login}</div>
        <div className={styles.infoRow}>{t('tariff')}: {tariffName}</div>
        <div className={styles.infoRow}>{t('pattern')}: {patternName}</div>

        <div className={styles.infoTitle}>{t('price')}</div>
        <div className={styles.infoRow}>{t('total')}: {formatPrice(price, currencies.ru)}</div>
      </div>
    </Modal>
  );
};

export default WorkspacePayedModal;
