import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Tooltip, Table } from '@forma/forma-ui-kit';
import Pagination from 'components/Pagination';
import LoadingIndicator from 'components/LoadingIndicator';
import WorkspacesListHeader from './WorkspacesListHeader';
import WorkspacesListItem from './WorkspacesListItem';

import styles from './workspaces-list.module.css';

const WorkspacesList = ({
  tariffs, items, featured, onSearch, onFilter, pagination,
  workspacesCount, usersCount, isLoading, actions
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <div className={styles.headerItem} title={t('count_workspaces')}>
            <ReactSVG src="/icons/company.svg" className={styles.headerIcon} wrapper="span" /> {workspacesCount}
          </div>
          <div className={styles.headerItem} title={t('count_users')}>
            <ReactSVG src="/icons/user.svg" className={styles.headerIcon} wrapper="span" /> {usersCount}
          </div>
        </div>
        <WorkspacesListHeader
          tariffs={tariffs}
          featured={featured}
          onChangeSearch={onSearch}
          onChangeFilter={onFilter}
        />
      </div>
      <div className={styles.tableWrap}>
        <Table className={styles.table} columns={[
          { children: t('user_account') },
          { children: <Tooltip control={<div><ReactSVG src="/icons/user.svg" /></div>}>{t('count_users')}</Tooltip>, className: styles.count },
          { children: <Tooltip control={<div><ReactSVG src="/icons/document-one.svg" /></div>}>{t('templates_created')}</Tooltip>, className: styles.count },
          { children: <Tooltip control={<div><ReactSVG src="/icons/document-many.svg" /></div>}>{t('documents_created')}</Tooltip>, className: styles.count },
          { children: <Tooltip control={<div><ReactSVG src="/icons/document-external.svg" /></div>}>{t('documents_signature')}</Tooltip>, className: styles.count },
          { children: <Tooltip control={<div><ReactSVG src="/icons/folder-thin.svg" /></div>}>{t('safe_used')}</Tooltip>, className: styles.count },
          { children: t('tariff'), className: styles.tariffHead },
          { children: t('last_login') },
          { children: t('registration') },
          { children: '' },
        ]}>
          {isLoading ? (
            <tr>
              <td colSpan={8}>
                <LoadingIndicator />
              </td>
            </tr>
          ) : (
            items?.map(item => (
              <WorkspacesListItem
                data={item}
                tariffs={tariffs}
                actions={actions}
                key={item.id}
              />
            ))
          )}
        </Table>
      </div>
      <Pagination {...pagination} />
    </div>
  );
};

export default WorkspacesList;
