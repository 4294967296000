import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer, Checkbox, Modal, RemoveModal, Tooltip } from '@forma/forma-ui-kit';
import WorkspaceBlock from '../WorkspaceBlock';
import EntityEdit from './EntityEdit';

import styles from './workspace-entities.module.css';

const WorkspaceEntities = ({ id: wid, items, onSubmit, onRemove, onAdd }) => {
  const { t } = useTranslation();
  const [ isAddOpen, setAddOpen ] = useState(false);
  const [ organizationForApprove, setOrganizationForApprove ] = useState(null);

  const handleAddOrganization = (data) => {
    onAdd({ ...data, wid });
    setAddOpen(false);
  };

  return (
    <WorkspaceBlock title="Организации для заполнения">
      <div className={styles.items}>
        {items?.map(item => {
          const { id, inn, kpp, name, address, email, approved } = item;
          // todo: change to universal format phone
          const x = (item.phone && item.phone.length >= 11) && item.phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
          const phone = x ? `+${x[1]}(${x[2]})-${x[3]}-${x[4]}-${x[5]}` : '';

          return (
            <div className={styles.row} key={id}>
              <div className={styles.icon}>
                <Tooltip
                  control={
                    <div onClick={() => setOrganizationForApprove(item)}>
                      <Checkbox id={`approved-${id}`} name={`approved-${id}`} checked={approved} readOnly />
                    </div>
                  }
                >
                  {approved ? 'Отменить подтверждение' : 'Подтвердить'}
                </Tooltip>
              </div>
              <div className={styles.left}>
                <div className={styles.date}>ИНН: {inn}</div>
                <div className={styles.date}>КПП: {kpp}</div>
              </div>
              <div className={styles.content}>
                <div className={styles.content}>Организация: {name}</div>
                <div className={styles.content}>Адрес: {address}</div>
                {(email || phone) && <div className={styles.content}>Email: {email}, Телефон: {phone}</div>}
                <div className={styles.content}>{approved}</div>
              </div>
              <div className={styles.actions}>
                <RemoveModal
                  title="Удаление организации"
                  control={
                    <Button
                      title={t('delete')}
                      className={styles.remove}
                      viewStyle="textLight"
                      iconClassName={styles.removeIcon}
                      icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                    />
                  }
                  itemName={name}
                  onRemove={() => onRemove({ wid, id })}
                />
              </div>
            </div>
          );
        })}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          size="small"
          onClick={() => setAddOpen(true)}
        >
          Добавить организацию
        </Button>
      </ButtonsContainer>

      <Modal
        size="large"
        open={isAddOpen}
        onClose={() => setAddOpen(false)}
        title="Добавление организации"
      >
        <EntityEdit
          onCancel={() => setAddOpen(false)}
          onAdd={handleAddOrganization}
        />
      </Modal>
      <Modal
        open={!!organizationForApprove}
        onClose={() => setOrganizationForApprove(null)}
        title="Подтверждение организации"
        buttons={[
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          },
          {
            children: t('submit'),
            viewStyle: 'primary',
            onClick: () => onSubmit({ wid: wid, id: organizationForApprove?.id, approved: !organizationForApprove?.approved })
          },
        ]}
      >
        <div className={styles.modalContent} style={{ textAlign: 'center' }}>
          {organizationForApprove?.approved
            ? "Вы уверены что хотите отменить подтверждение организации?"
            : "Вы уверены что хотите подтвердить организацию?"
          }
        </div>
      </Modal>
    </WorkspaceBlock>
  );
};

export default WorkspaceEntities;
