import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, LoadingButton, Input, Textarea, Checkbox, Select } from '@forma/forma-ui-kit';
import ControlField from 'components/fields/ControlField';

import styles from './template-edit.module.css';

const fields = [
  {
    name: 'approved',
    type: 'checkbox'
  },
  {
    name: 'showHTML',
    type: 'checkbox'
  },
  {
    name: 'seoName',
    type: 'input'
  },
  {
    name: 'seo',
    type: 'textarea'
  },
  {
    name: 'description',
    type: 'textarea'
  },
  {
    name: 'keyWords',
    type: 'select',
    creatable: true
  },
  {
    name: 'categories',
    type: 'select'
  },
  {
    name: 'hashtags',
    type: 'select',
    creatable: true
  }
]

const TemplateEdit = ({ data, categoriesList, onSave, isSaveLoading }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, control, setValue, formState: { errors, isValid } } = useForm({
    mode: 'onChange'
    // resolver: user && yupResolver(getValidationSchema(fields))
  });

  useEffect(() => {
    if (!data) return;
    if (data.seo) setValue('seo', data.seo);
    if (data.name) setValue('name', data.name);
    if (data.description) setValue('description', data.description);
    if (data.keyWords) setValue('keyWords', data.keyWords);
    if (data.categories) setValue('categories', data.categories.map(({ id }) => id));
    if (data.hashtags) setValue('hashtags', data.hashtags);
    if (data.approved) setValue('approved', data.approved);
    if (data.showHTML) setValue('showHTML', data.showHTML);
    if (data.seoName) setValue('seoName', data.seoName);
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = (values) => {
    onSave(values);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.title}>{t('description')}</div>
      <div className={styles.fields}>
        {fields.map(({ name, type, creatable }) => {
          let options = [];
          if (name === 'categories' && categoriesList) options = categoriesList.map(({ id, name }) => ({ label: name, value: id }));
          else if (type === 'select' && data && data[name]) options = data[name].map(value => ({ label: value, value: value }));

          if (type === 'textarea') return (
            <ControlField
              component={Textarea}
              id={name}
              name={name}
              register={register}
              control={control}
              maxLength={9999}
              meta={{
                label: t(`vars.${name}`),
                error: errors[name] && errors[name].message,
                fullWidth: true
              }}
              key={name}
            />
          );

          if (type === 'select') return (
            <ControlField
              component={Select}
              id={name}
              name={name}
              options={options}
              multiple
              register={register}
              control={control}
              meta={{
                label: t(`vars.${name}`),
                error: errors[name] && errors[name].message,
                fullWidth: true,
                creatable: creatable,
                showTags: true
              }}
              key={name}
            />
          );

          if (type === 'checkbox') return (
            <Controller
              control={control}
              id={name}
              name={name}
              key={name}
              render={({ field: { value = false, ...rest } }) => (
                <Checkbox
                  containerClass={styles.checkbox}
                  label={t(`vars.${name}`)}
                  error={errors[name] && errors[name].message}
                  checked={value}
                  {...rest}
                />
              )}
            />
          );

          return (
            <ControlField
              component={Input}
              id={name}
              name={name}
              register={register}
              control={control}
              meta={{
                label: t(`vars.${name}`),
                error: errors[name] && errors[name].message,
                fullWidth: true
              }}
              key={name}
            />
          );
        })}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          className={styles.button}
          viewStyle="primary"
          disabled={!isValid}
          isLoading={isSaveLoading}
        >
          {t('save')}
        </LoadingButton>
      </ButtonsContainer>
    </form>
  );
};

export default TemplateEdit;
