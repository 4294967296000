import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Modal, FillingItem } from '@forma/forma-ui-kit';

import { useAddAdminMutation } from 'store/admins/adminsApi';
import { adminProfileSchema } from 'data/schemas';
import { formValuesToData } from 'helpers/formValuesToData';

import styles from './admin-edit-modal.module.css';

const AdminEditModal = ({ open, onClose, id }) => {
  const { t } = useTranslation();
  // const { data } = useGetAdminByIdQuery(id);
  const [ addAdmin, { isLoading: isAddLoading } ] = useAddAdminMutation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    // defaultValues: data
    // resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit = (values) => {
    addAdmin(formValuesToData(adminProfileSchema, values));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={styles.root}
      title={id ? t('edit_administrator') : t('add_administrator')}
      buttons={[
        {
          children: t('cancel'),
          viewStyle: 'tertiary'
        },
        {
          children: t('save'),
          viewStyle: 'primary',
          disabled: !isValid,
          isLoading: isAddLoading,
          onClick: () => handleSubmit(onSubmit)
        },
      ]}
    >
      <div className={styles.content}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fields}>
            {Object.keys(adminProfileSchema).map(key => {
                const attrs = { ...adminProfileSchema[key] };

                return (
                  <FillingItem
                    data={{
                      ...attrs,
                      id: key,
                      required: attrs.required ?? false,
                      name: t(`vars.${key}`),
                      errorMessage: t('errors.regexp'),
                    }}
                    form={{
                      register: register,
                      control: control,
                      error: errors[key] && errors[key].message,
                    }}
                    options={attrs.options}
                    key={key}
                  />
                )
              }
            )}
          </div>
        </form>
      </div>
    </Modal>
  )
};

export default AdminEditModal;
