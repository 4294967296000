
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RemoveModal } from '@forma/forma-ui-kit';
import { useRemoveWorkspaceMutation } from 'store/workspaces/workspacesApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const WorkspaceRemoveModal = ({ open, onClose, control, workspaceId, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ removeWorkspace ] = useRemoveWorkspaceMutation();

  const handleSubmit = () => {
    removeWorkspace(workspaceId).then(() => {
      dispatch(setNotification(t('workspace_was_deleted'), 'ERROR'));
    });
  };

  return (
    <RemoveModal
      open={open}
      onClose={onClose}
      control={control}
      title={t('remove_workspace')}
      message={t('are_you_sure_delete_workspace', { login: user?.login })}
      onRemove={handleSubmit}
    />
  );
};

export default WorkspaceRemoveModal;
