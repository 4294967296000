import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';
import { Modal, Button, Input, ContextMenu } from '@forma/forma-ui-kit';

import styles from './workspace-actions.module.css';
import { WorkspacePayedModal, WorkspaceRemoveModal, WorkspaceSignatureModal } from 'pages/Users/modals';

const WorkspaceActions = ({ data, tariffs, actions }) => {
  const { t } = useTranslation();

  const {
    id, user, tariff, dtTo, workspace, isPaid, name, price, patternId, tcrequestsNotProcessedCount
  } = data;

  const { onClickSignAs, onClickDownloadRequests, onChangeTariff, onProlongTariff } = actions;

  const [ changeTariffTo, setChangeTariffTo ] = useState(null);
  const [ addUsers, setAddUsers ] = useState('');
  const [ addAttachmentsSize, setAddAttachmentsSize ] = useState('');

  const [ showingModal, setShowingModal ] = useState(null);

  const options = [
    {
      text: t('create_bill'),
      disabled: true
    },
    {
      text: t('download_templates'),
      disabled: !tcrequestsNotProcessedCount,
      onClick: () => onClickDownloadRequests(workspace.id)
    },
    {
      text: t('increase_signature'),
      align: 'left',
      onClick: () => setShowingModal('signature')
    },
    {
      text: t('change_tariff'),
      align: 'left',
      items: tariffs?.map(tariffItem => (
        {
          align: 'left',
          text: tariffItem.name,
          icon: tariff.id === tariffItem.id ? <ReactSVG src="/icons/checked.svg" className={styles.checkedIcon} wrapper="span" /> : '',
          items: tariffItem.patterns.map(patternItem => ({
            text: patternItem.name,
            icon: patternId === patternItem.id ? <ReactSVG src="/icons/checked.svg" className={styles.checkedIcon} wrapper="span" /> : '',
            disabled: !patternItem.canChoose,
            onClick: () => setChangeTariffTo({ tariff: tariffItem, pattern: patternItem })
          }))
        }
      ))
    },
  ];

  if (tariff?.id === 'tariff_trial') options.push(
    {
      text: t('prolong_tariff_for'),
      align: 'left',
      items: [
        {
          text: <span><Trans i18nKey="count_days" values={{ count: 5 }} /></span>,
          onClick: () => {
            const oldDate = new Date() > new Date(dtTo) ? new Date() : new Date(dtTo);
            const dateTo = addDays(oldDate, 5);
            onProlongTariff(id, dateTo.toISOString());
          },
        },
        {
          text: <span><Trans i18nKey="count_days" values={{ count: 14 }} /></span>,
          onClick: () => {
            const oldDate = new Date() > new Date(dtTo) ? new Date() : new Date(dtTo);
            const dateTo = addDays(oldDate, 14);
            onProlongTariff(id, dateTo.toISOString());
          },
        }
      ]
    }
  );

  options.push(
    {
      text: isPaid ? t('paid') : t('mark_paid'),
      icon: isPaid ? <ReactSVG src="/icons/checked.svg" className={styles.checkedIcon} wrapper="span" /> : '',
      onClick: () => !isPaid && setShowingModal('payed')
    },
    {
      text: t('remove_workspace'),
      onClick: () => setShowingModal('remove')
    },
    {
      text: t('sign_as_user'),
      onClick: () => onClickSignAs(workspace.id)
    }
  );

  return (
    <div>
      <ContextMenu
        control={
          <Button
            className={styles.buttonSettings}
            viewStyle="text"
            iconClassName={styles.buttonIcon}
            icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
          />
        }
        offsetY={16}
        position={[ 'left top', 'left bottom', 'right top', 'right bottom' ]}
        items={options}
      />

      <Modal
        open={!!changeTariffTo}
        onClose={() => { setChangeTariffTo(null); setAddUsers(''); }}
        title={t('change_tariff')}
        buttons={[
          {
            children: t('submit'),
            onClick: () => onChangeTariff(workspace.id, { id, ...changeTariffTo, addUsersCount: addUsers, addAttachmentsSize })
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          },
        ]}
      >
        <div className={styles.modalContent}>
          <div className={styles.infoTitle}>{t('user_data')}</div>
          <div className={styles.infoRow}>{t('user')}: {user.login}</div>
          <div className={styles.infoRow}>{t('tariff')}: {tariff.name}</div>
          <div className={styles.infoRow}>{t('pattern')}: {name}</div>
          <div className={styles.infoRow}>
            {new Date(dtTo) - new Date() > 0 ? (
              t('active_for_days', { count: Math.floor((new Date(dtTo) - new Date()) / (1000 * 60 * 60 * 24)) })
            ) : (
              t('not_active')
            )}
          </div>

          <div className={styles.infoTitle}>{t('new_tariff')}</div>
          {changeTariffTo && (
            <>
              <div className={styles.infoRow}>{t('tariff')}: {changeTariffTo.tariff.name}</div>
              <div className={styles.infoRow}>{t('pattern')}: {changeTariffTo.pattern.name}</div>
              <div className={styles.infoInputs}>
                <Input
                  type="number"
                  name="addAttachmentsSize"
                  viewStyle="secondary"
                  className={styles.infoInput}
                  placeholder={'1 Гб'}
                  onChange={e => setAddAttachmentsSize(e.target.value)}
                  value={addAttachmentsSize}
                  meta={{
                    label: 'Кол-во дополнительных Гб',
                  }}
                />
                {changeTariffTo.tariff.canAddUsers && (
                  <Input
                    type="number"
                    name="addUsersCount"
                    viewStyle="secondary"
                    className={styles.infoInput}
                    placeholder={t('users_count')}
                    onChange={e => setAddUsers(e.target.value)}
                    meta={{
                      label: t('vars.canAddUsers'),
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Modal>

      <WorkspacePayedModal
        open={showingModal === 'payed'}
        onClose={() => setShowingModal(null)}
        instanceId={id}
        user={user}
        tariffName={tariff.name}
        patternName={name}
        price={price}
      />

      <WorkspaceRemoveModal
        open={showingModal === 'remove'}
        onClose={() => setShowingModal(null)}
        workspaceId={workspace.id}
        user={user}
      />

      <WorkspaceSignatureModal
        open={showingModal === 'signature'}
        onClose={() => setShowingModal(null)}
        workspaceId={workspace.id}
      />
    </div>
  );
};

export default WorkspaceActions;
