import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Button, Select } from '@forma/forma-ui-kit';
import WorkspaceBlock from '../WorkspaceBlock';
import StatisticCols from './StatisticCols';

import styles from './workspace-statistic.module.css';
import StatisticChart from './StatisticChart';
import classNames from 'classnames';

const types = [ 'templates', 'renders', 'packs', 'render_packs' ];
const periods = [ 'week', 'month', 'quarter', 'year' ];

const WorkspaceStatistic = ({ data, params, onChangeFilter }) => {
  const { t } = useTranslation();
  const [ viewPeriod, setViewPeriod ] = useState('week');
  const [ isViewChart, setViewChart ] = useState(false);

  return (
    <WorkspaceBlock>
      <div className={styles.header}>
        <Select
          options={types.map(type => ({ label: t(type), value: type }))}
          onChange={e => onChangeFilter('type', e.target.value)}
          value={params.type}
          meta={{
            containerClass: styles.select
          }}
        />
        <Button
          className={styles.buttonChart}
          viewStyle="secondary"
          icon={<ReactSVG src={isViewChart ? '/icons/grid.svg' : '/icons/chart.svg'} wrapper="span" />}
          onClick={() => setViewChart(prev => !prev)}
        />
        {isViewChart && (
          periods.map(period => (
            <Button
              className={classNames(styles.button, viewPeriod === period && styles.active)}
              viewStyle="secondary"
              onClick={() => setViewPeriod(period)}
              key={period}
            >
              {t(period)}
            </Button>
          ))
        )}
      </div>
      {data && (
        <div className={styles.content}>
          {isViewChart ? (
            <StatisticChart
              data={data[viewPeriod]?.values.map(({ date, count }) => {
                if (viewPeriod === 'week') return { name: format(new Date(date), 'EEEEEE', { locale: ru }), value: count };
                if (viewPeriod === 'month') return { name: format(new Date(date), 'dd'), value: count };
                if (viewPeriod === 'quarter') return { name: t(`month_names.${new Date(date).getMonth()}`), value: count };
                if (viewPeriod === 'year') return { name: t(`month_names.${new Date(date).getMonth()+1}`), value: count };
                return null;
              })}
              total={data[viewPeriod]?.total}
            />
          ) : (
            <StatisticCols
              params={params}
              week={data?.week}
              month={data?.month}
              quarter={data?.quarter}
              year={data?.year}
              onChangeMonth={(value) => onChangeFilter('month', value)}
              onChangeYear={(value) => onChangeFilter('year', value)}
            />
          )}
        </div>
      )}
    </WorkspaceBlock>
  );
};

export default WorkspaceStatistic;
